import React, { FC, useCallback } from 'react';

import IconCustom from 'components/common/IconCustom';
import CompatibilityItem from './CompatibilityItem';
import { ICompatibilityListProps } from './models';
import './CompatibilityList.scss';

const CompatybilityList: FC<ICompatibilityListProps> = ({
  hasRecommendedProducts,
  tags,
  heading,
  type,
  titleRecommendedProducts,
}) => {
  const visibleTags = tags?.filter(({ visible }) => visible === null || visible === '1');
  const isNotEmptyTags = !!(visibleTags && visibleTags.length);
  const handleScroll = useCallback(() => {
    const recommended = document.getElementById('recommended-products');
    if (recommended) {
      recommended.scrollIntoView();
    }
  }, []);

  return (
    <div className="dt-compatibility-list">
      {type === 'product' && isNotEmptyTags ? (
        <h3 className="dt-compatibility-list__title">
          <IconCustom icon="tick_white_icon" />
          {heading}
        </h3>
      ) : null}
      {isNotEmptyTags ? (
        <ul className="dt-compatibility-list__tags">
          {visibleTags.map((item) => (
            <CompatibilityItem key={item.id} {...item} />
          ))}
        </ul>
      ) : null}
      {type === 'article' && hasRecommendedProducts ? (
        <button
          type="button"
          onClick={handleScroll}
          className="dt-compatibility-list__recommended-link"
        >
          <IconCustom icon="arrow_in_circle_icon" />
          <span>{titleRecommendedProducts || 'Recommended products'}</span>
        </button>
      ) : null}
    </div>
  );
};
export default CompatybilityList;
