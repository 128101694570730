import React, { FC } from 'react';
import Container from 'react-bootstrap/Container';

import HTag from 'components/common/HTag';
import ProductCarousel from 'components/common/ProductCarousel';
import ProductItem from 'components/common/ProductItem';

import { CarouselMasks } from 'components/common/ProductCarousel/models';
import { IRelatedProducts } from './models';

import './RelatedProducts.scss';

const RelatedProducts: FC<IRelatedProducts> = ({
  products = [],
  title: { regularText, boldText },
  useFixedProductImage,
}) => (
  <section className="dt-related" aria-label={`${regularText} ${boldText}`}>
    <Container fluid>
      <HTag underline size={2} regularText={regularText} boldText={boldText} />
      <ProductCarousel
        type={CarouselMasks.noMasks}
        infinite={false}
        limitWidth={products?.length < 4}
        isBtnGroup={products?.length > 1}
      >
        {products?.map((item) => (
          <ProductItem key={item.id} {...item} useFixedProductImage={useFixedProductImage} />
        ))}
      </ProductCarousel>
    </Container>
  </section>
);

export default RelatedProducts;
