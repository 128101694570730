import React, { FC, useEffect, useCallback } from 'react';
import { graphql } from 'gatsby';
import loadable from '@loadable/component';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Layout from 'components/Layout';
import ProductInfo from 'components/ProductInfo';
import ProductDetailedInfo from 'components/ProductDetailedInfo';
import RelatedProducts from 'components/RelatedProducts';
import DidYouKnowLoadableWrapper from 'components/FooterPromo/DidYouKnowLoadable';

import isEmpty from 'utils/functions';
import useBVDynamicCatalog from 'hooks/useBVDynamicCatalog/useBVDynamicCatalog';
import { gtmService } from 'services/gtmService';

import { TBodyRendererComponent, TComponentProps } from './models';

import 'styles/main.scss';
import './ProductPage.scss';

enum ProductBodyBlockName {
  FEATURES = 'Features',
  HOW_TO_USE = 'How to use',
  BANNER = 'Banner',
  SAFETY_INSTRUCTIONS = 'Safety Instructions',
  INGREDIENTS = 'Ingredients',
  FAQS = "FAQ's",
  TEXT_BLOCK = 'Text Block',
}

const Wrapper: FC = ({ children }) => (
  <Container fluid>
    <Row className="justify-content-md-center">
      <Col xs={12} lg={8}>
        {children}
      </Col>
    </Row>
  </Container>
);

const BodyRenderer: FC<TBodyRendererComponent> = ({
  product,
  bodyItem: { structure, properties },
  siteSettings,
}) => {
  switch (structure) {
    case ProductBodyBlockName.FEATURES: {
      const ProductFeatures = loadable(() => import('components/ProductFeatures'));

      return (
        <div
          key={`product-${structure}-body-block-${properties.titleRegular}`}
          className="dt-product-feature-wrapper dt-container-wrapper"
        >
          <Container fluid>
            <ProductFeatures
              features={properties.features}
              titleRegular={properties.titleRegular}
              titleBold={properties.titleBold}
            />
          </Container>
        </div>
      );
    }
    case ProductBodyBlockName.HOW_TO_USE: {
      const HowToUse = loadable(() => import('components/HowToUse'));

      return (
        <Wrapper key={`product-${structure}-body-block-${properties.imageAlt}`}>
          <HowToUse
            image={properties.image}
            imageAlt={properties.imageAlt}
            description={properties.description}
            videoUrl={properties.videoUrl}
            recommendations={properties.recommendations}
            titleRegular={properties.titleRegular}
            titleBold={properties.titleBold}
            listTitleText={properties.listTitleText}
            actionLabelText={properties.actionLabelText}
          />
        </Wrapper>
      );
    }
    case ProductBodyBlockName.BANNER: {
      const ProductBanner = loadable(() => import('components/ProductBanner'));

      return (
        <ProductBanner
          key={`product-${structure}-body-block-${properties.imageAlt}`}
          title={properties.title}
          description={properties.description}
          image={properties.image}
          imageAlt={properties.imageAlt}
          productImage={product.localHeroImage}
          productImageAlt={properties.productImageAlt}
          actionIconName={properties.actionIconName}
          useFixedProductImage={siteSettings.useFixedProductGatsbyImage}
        />
      );
    }
    case ProductBodyBlockName.SAFETY_INSTRUCTIONS: {
      const SafetyInstructions = loadable(() => import('components/SafetyInstructions'));

      return (
        <Wrapper key={`product-${structure}-body-block-${properties.titleRegular}`}>
          <SafetyInstructions
            instructions={properties.instructions}
            titleBold={properties.titleBold}
            titleRegular={properties.titleRegular}
            showMoreText={properties.showMoreText}
            additionalText={properties.additionalText}
          />
        </Wrapper>
      );
    }
    case ProductBodyBlockName.INGREDIENTS: {
      const ProductIngredients = loadable(() => import('components/ProductIngredients'));

      return (
        <ProductIngredients
          key={`product-${structure}-body-block-${properties.imageAlt}`}
          group={properties.group}
          image={properties.image}
          imageAlt={properties.imageAlt}
          buttonLink={properties.buttonLink}
          buttonText={properties.buttonText}
          titleRegular={properties.titleRegular}
          description={properties.description}
        />
      );
    }
    case ProductBodyBlockName.FAQS: {
      const Faqs = loadable(() => import('components/Faqs'));

      return (
        <Wrapper key={`product-${structure}-body-block`}>
          <Faqs faqs={properties.questionAnswerItems} faqTitle={siteSettings?.faqTitle} />
        </Wrapper>
      );
    }
    case ProductBodyBlockName.TEXT_BLOCK: {
      const DangerouslySetInnerHtml = loadable(
        () => import('components/common/DangerouslySetInnerHtml')
      );

      return (
        <Wrapper key={`product-${structure}-body-block`}>
          <DangerouslySetInnerHtml
            html={properties.articleSectionDescription}
            className="dt-article-main-text"
          />
        </Wrapper>
      );
    }
    default:
      return null;
  }
};

const ProductPage: FC<TComponentProps> = ({
  pageContext: { breadCrumbs, sizeScentFriendlyProductLinks },
  data: {
    product,
    relatedProducts,
    sizeScentFriendlyProducts,
    siteSettings,
    brandSettings,
    homepageSettings,
    menu,
    footerNavigation,
    mobileAppPromoBanner,
    languageSelector,
  },
}) => {
  const {
    seoMetaTitle,
    seoMetaDescription,
    seoMetaKeywords,
    seoCanonicalUrl,
    seoExternalHreflangs,
    seoImage,
    link,
  } = product;

  const preloadImage = product.localHeroImage?.childImageSharp?.fluid.srcSetWebp;

  const scentFriendlyProducts = sizeScentFriendlyProductLinks?.length
    ? sizeScentFriendlyProducts.nodes
        ?.slice(0)
        ?.sort(
          (a, b) =>
            sizeScentFriendlyProductLinks.indexOf(a?.link) -
            sizeScentFriendlyProductLinks.indexOf(b?.link)
        )
    : sizeScentFriendlyProducts.nodes;

  useBVDynamicCatalog(product, siteSettings.lang, brandSettings.brandName);

  useEffect(() => {
    const timerId = gtmService.emitProductView(product);

    return () => {
      timerId && clearTimeout(timerId);
    };
  }, []);

  const handleRetailerClick = useCallback(
    (productData: ProductTypes.IProduct) => (shopName: string | string[]) => {
      gtmService.emitRetailerClick(productData, shopName);
    },
    []
  );

  return (
    <Layout
      seo={{
        seoMetaTitle,
        seoMetaDescription,
        seoMetaKeywords,
        seoCanonicalUrl,
        seoExternalHreflangs,
        seoImage,
        preloadImage,
      }}
      siteSettings={siteSettings}
      menu={menu}
      footerNavigation={footerNavigation}
      mobileAppPromoBanner={mobileAppPromoBanner}
      homepageSettings={homepageSettings}
      className="product-page"
      languageSelector={languageSelector}
      url={link}
    >
      <Container fluid>
        <div className="dt-top-info product-page">
          <Row>
            <Col sm={12} md={6}>
              <ProductInfo
                id={product.ean || product.sku}
                title={product.name}
                breadcrumbs={breadCrumbs}
                text={product.descriptionShort}
                type="product"
                imgSet={
                  product.localHeroImage
                    ? [product.localHeroImage, ...(product.localImages || [])]
                    : product.localImages
                }
                backGroundImage={product.backgroundImage}
                alt={product.name}
                buyNow={product.buyNow}
                size={product.size}
                scent={product.scent}
                sizeScentFriendlyProducts={scentFriendlyProducts}
                newsletterBanner={homepageSettings.newsletterBanner}
                discountBanner={homepageSettings.discountBanner}
                productColor={product.productColor[0]}
                isLoggingProductSelection={homepageSettings.pushSelectedEventGTM !== '0'}
                newsletterSignUp={siteSettings?.newsletterSignUp}
                enableSignupButtonInProductPage={siteSettings?.enableSignupButtonInProductPage}
                discountButton={siteSettings?.discountButton}
                showBV={siteSettings?.useBV || ''}
                customFields={{
                  buttonBuyNow: siteSettings?.buttonBuyNow || '',
                  orderNowFrom: siteSettings?.orderNowFrom || '',
                  buttonCancel: siteSettings?.buttonCancel || '',
                }}
                retailerClickHandler={handleRetailerClick(product)}
                useFixedProductImage={siteSettings.useFixedProductGatsbyImage}
              />
            </Col>
          </Row>
          <Row>
            <Col sm={12} md={6} lg={6}>
              <ProductDetailedInfo
                type="product"
                tags={product.tags}
                titleSecondary={product.titleBrandbank}
                textFull={product.descriptionLong}
                heading={product.tagsLabelText}
              />
            </Col>
          </Row>
        </div>
      </Container>
      {product.productBody.map((bodyItem) => (
        <BodyRenderer
          key={bodyItem.structure}
          bodyItem={bodyItem}
          product={product}
          siteSettings={siteSettings}
        />
      ))}
      {!isEmpty(relatedProducts.nodes) && (
        <RelatedProducts
          products={relatedProducts.nodes}
          title={{
            regularText: siteSettings?.relatedProductsRegularTitle ?? 'Related',
            boldText: siteSettings?.relatedProductsBoldTitle ?? 'Products',
          }}
          useFixedProductImage={siteSettings.useFixedProductGatsbyImage}
        />
      )}
      <DidYouKnowLoadableWrapper didYouKnow={product.didYouKnow} mask />
    </Layout>
  );
};

export const query = graphql`
  query (
    $link: String!
    $relatedProductLinks: [String] = ""
    $sizeScentFriendlyProductLinks: [String] = ""
    $lang: String
  ) {
    languageSelector(lang: { eq: $lang }) {
      ...FragmentLanguageSwitcher
    }
    siteSettings(lang: { eq: $lang }) {
      ...FragmentSiteSettings
      lang
      useBV
      buttonBuyNow
      orderNowFrom
      buttonCancel
      relatedProductsRegularTitle
      relatedProductsBoldTitle
      faqTitle
    }
    brandSettings {
      brandName
    }
    menu(lang: { eq: $lang }) {
      ...FragmentHeader
    }
    footerNavigation(lang: { eq: $lang }) {
      ...FragmentFooter
    }
    mobileAppPromoBanner(lang: { eq: $lang }) {
      ...FragmentMobileAppPromoBanner
    }
    homepageSettings(lang: { eq: $lang }) {
      ...FragmentHomepageSettings
      pushSelectedEventGTM
      newsletterBanner {
        buttonText
        buttonURL {
          url
        }
        titleText
        regularTitle
      }
      discountBanner {
        buttonText
        buttonURL {
          url
          queryString
        }
        titleText
        regularTitle
      }
    }
    sizeScentFriendlyProducts: allUmbracoProduct(
      filter: { link: { in: $sizeScentFriendlyProductLinks }, lang: { eq: $lang } }
    ) {
      nodes {
        link
        size
        scent
      }
    }
    product: umbracoProduct(link: { eq: $link }, lang: { eq: $lang }) {
      seoMetaKeywords
      seoMetaDescription
      seoMetaTitle
      seoCanonicalUrl
      seoImage
      seoExternalHreflangs {
        key
        value
      }
      name
      sku
      ean
      umbracoId
      link
      buyNow {
        properties {
          link {
            url
          }
          shopName
          extraClass
          name
        }
      }
      titleBrandbank
      scent
      productColor
      size
      descriptionLong
      descriptionShort
      images
      backgroundImage {
        fluid {
          srcSet
          base64
        }
        fallbackUrl
      }
      tagsLabelText
      heroImage
      tags {
        id
        title
        name
        visible
      }
      localHeroImage {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
        fixedMobileGalleryImage: childImageSharp {
          fixed(height: 300, quality: 90) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
        fixedDesktopGalleryImage: childImageSharp {
          fixed(height: 420, quality: 90) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
        fixedMobilePromoImage: childImageSharp {
          fixed(height: 180, quality: 90) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
        fixedTabletPromoImage: childImageSharp {
          fixed(height: 287, quality: 90) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
        fixedDesktopPromoImage: childImageSharp {
          fixed(height: 465, quality: 90) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      didYouKnow {
        labelText
        descriptionText
        buttonText
        imageAlt
        ariaLabel
        didYouKnowBG {
          fluid {
            srcSet
            base64
          }
          fallbackUrl
        }
        buttonURL {
          url
          icon
        }
      }
      productBody {
        structure
        properties {
          description
          iconName
          title
          titleRegular
          titleBold
          listTitleText
          showMoreText
          actionIconName
          actionLabelText
          articleSectionDescription
          recommendations {
            properties {
              answer
              question
            }
          }
          features {
            properties {
              description
              imageAlias
            }
          }
          group {
            properties {
              name
              composition
              iconName
            }
          }
          image {
            fallbackUrl
            fluid {
              srcSet
              base64
            }
          }
          imageAlt
          productImage {
            fallbackUrl
            fluid {
              srcSet
              base64
            }
          }
          productImageAlt
          instructions {
            properties {
              description
              iconName
            }
          }
          questionAnswerItems {
            properties {
              answer
              question
            }
          }
          videoUrl
          buttonLink {
            url
            name
          }
          buttonText
          additionalText
        }
      }
      category
    }
    relatedProducts: allUmbracoProduct(
      filter: { link: { nin: [$link], in: $relatedProductLinks }, lang: { eq: $lang } }
    ) {
      nodes {
        id
        link
        size
        localHeroImage {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
          fixedMobileRelatedImage: childImageSharp {
            fixed(height: 260, quality: 90) {
              ...GatsbyImageSharpFixed_withWebp_noBase64
            }
          }
        }
        name
        recognisableFeature
        descriptionLong
        descriptionShort
        titleBrandbank
      }
    }
  }
`;

export default ProductPage;
