import React, { FC } from 'react';

import CompatibilityList from '../CompatibilityList';
import { IProductDetailedInfoProps } from './models';
import './ProductDetailedInfo.scss';
import DangerouslySetInnerHtml from '../common/DangerouslySetInnerHtml';

const ProductDetailedInfo: FC<IProductDetailedInfoProps> = ({
  titleSecondary,
  textFull,
  type = 'product',
  tags,
  heading,
  hasRecommendedProducts,
  titleRecommendedProducts,
}) => (
  <div className="dt-detailed-info">
    {type === 'product' && (
      <div className="dt-detailed-info__description">
        {titleSecondary?.trim() ? (
          <h2 className="dt-detailed-info__description__title">{titleSecondary}</h2>
        ) : null}
        <DangerouslySetInnerHtml className="dt-detailed-info__description__text" html={textFull} />
      </div>
    )}

    <CompatibilityList
      hasRecommendedProducts={hasRecommendedProducts}
      tags={tags}
      type={type}
      heading={heading}
      titleRecommendedProducts={titleRecommendedProducts}
    />
  </div>
);

export default ProductDetailedInfo;
