import React, { FC } from 'react';
import { navigate } from 'gatsby';

import Button from 'react-bootstrap/Button';
import { getLocationLang } from 'utils/browser';

const CompatybilityItem: FC<PageContent.TTags> = ({ name, title }) => {
  const handleClick = () => {
    const pathLang = getLocationLang();
    const langPrefix = pathLang ? `/${pathLang}` : '';
    navigate(langPrefix.concat(`/search-results/?query=${title || name}`));
  };

  return (
    <li>
      <Button onClick={handleClick} variant="outline-secondary" size="sm">
        {title || name}
      </Button>
    </li>
  );
};

export default CompatybilityItem;
